.calendar {
  display: flex;
  align-items: center;

  .calendar-to {
    width: 16px;
    font-size: 13px;
    text-align: center;
    line-height: 35px;

    .line {
      border-width: $border-width;
      border-color: rgb(126, 134, 138);
      border-style: solid;
      height: 0;
    }
  }
}
