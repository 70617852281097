
              @import "@/assets/css/variables.scss";
            



















































































































































































































































































































































































































































































































































































































































































































































































































































































































.dialog_inner_form {
  .el-select {
    width: 100%;
  }
}
.trans_status_popover {
  &.el-popover {
    width: 463px;
    box-sizing: border-box;
    @include screen-mobile {
      max-width: 360px;
      width: 360px;
    }
    .popover_inner {
      .status_item {
        margin-bottom: 16px;
        .item_left {
          display: flex;
          align-items: center;
          margin-bottom: 3px;
          .color_dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            @include rtl-sass-prop(margin-right, margin-left, 10px);
          }
          .status_name {
            color: $text-primary;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600px;
          }
        }
        .status_desc {
          color: $text-placeholder;
          font-size: 12px;
          line-height: 18px;
          @include rtl-sass-prop(padding-left, padding-right, 18px);
          a {
            color: $text-link;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.success_bg {
  background-color: #30bf58;
}
.fail_bg {
  background-color: #ffde32;
}
.rejected_bg {
  background-color: #cd2a2a;
}
.pending_bg {
  background-color: #f39924;
}
.incomplete_bg {
  background-color: #acacbf;
}
.status_popover_icon {
  width: 16px;
  color: $primary;
  @include rtl-sass-prop(margin-left, margin-right, 10px);
}

.amount-detail-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 36px;
}

.payment-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.payment-img {
  height: 84px;
  width: auto;
  max-width: 180px;
  margin-bottom: 8px;

  &.visa_icon,
  &.fasapay_icon,
  &.skrill_icon,
  &.neteller_icon,
  &.sticpay_icon,
  &.astropay_icon {
    max-height: 40px;
  }
}
.payment-title {
  font-size: 16px;
  font-weight: 400;
  color: $pu-dark-gray;
}
.payment-amount {
  font-size: 30px;
  font-weight: 800;
  color: $blue;
  line-height: 40px;
}

.payment-detail {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    justify-content: space-between;
    .payment-title {
      text-align: left;
    }
    .payment-text {
      font-size: 16px;
      font-weight: 900;
      text-align: right;
      max-width: 300px;
      word-break: break-word;
    }
  }

  .item + .item {
    margin-top: 10px;
  }
}

.bank-statement-btn {
  margin-top: 40px;
}

@media (max-width: 540px) {
  .payment-detail {
    .item {
      flex-direction: column;
      align-items: center;
      .payment-text {
        text-align: left;
        margin-top: 8px;
      }
    }
  }
}
